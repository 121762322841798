import react, { Component, createRef } from 'react';

import'./Button.Style.scss';

export default class Button extends Component
{
	constructor( props )
	{
		super( props );

		this.buttonRef = createRef();
		
		this.onClick = this.#click.bind( this );

		this.state = 
		{
			loader: false
		};
	}

	#click = ()=>
	{
		this.setState( { loader : true } );
		this.props.onClick && this.props.onClick( this.buttonRef.current );

		setTimeout( () => 
		{
			this.setState( { loader : false } )
		}, 2500 )
	}

	loader( state )
	{
		this.setState( { loader : state } );
	}

	render()
	{
		const props = this.props;

		return (
			<div 
				ref={ this.buttonRef }
				onClick={ this.onClick }
				className={ [
					'oak-button',
					( props.color ? `oak-button-${props.color}` : '' ),
					( props?.disabled ? 'oak-button-disabled' : '' ),
					props.className
				].join( ' ' ).trim() }>
					{ this.props?.loader 
						&& this.state.loader 
						&& <i className="oak-button-loader fa-solid fa-circle-notch fa-spin"></i> }
					{ props.label }
			</div>
		);
	}
}