import react, { Component, createRef } from 'react';

import'./Input.Style.scss';

export default class Input extends Component
{
	constructor( props )
	{
		super( props );

		this.inputRef = createRef();
		
		this.onChange = this.#change.bind( this );
	}

	#change = ()=>
	{
		console.log( this.inputRef.current );
	}

	focusClick()
	{
        
		this.inputRef?.current.focus();
    }


	render()
	{
		const props = this.props;

		return (
			<div className={ [
				'oak-input',
				( props?.disabled ? 'oak-input-disabled' : '' ),
				props.className
			].join( ' ' ).trim() }>
				{ props?.label && 
				<label htmlFor={ props?.name }>
					{ props?.label }
				</label> }
				<input 
					id={ props?.id }
					name={ props?.name }
					type={ props.type }
					ref={ this.inputRef } 
					defaultValue={ props?.value }
					placeholder={ props?.placeholder }
					disabled={ props?.disabled }
					
					onChange={ this.onChange }

					autoComplete={ "off" }
					/>
			</div>
		);
	}
}

/*
<div 
style={'width:'+(typeof width !== 'undefined' ? width : '100%')+';'+(typeof style !== 'undefined' ? style : '')} 
class={'input-text'+
	(typeof className !== 'undefined' ? ' '+className : '')+
	(typeof value !== 'undefined' && value !== '' ? ' fill' : '')+
	(typeof disabled !== 'undefined' && disabled === 'true' ? ' disabled' : '')+
	(typeof label === 'undefined' ? ' not-label' : '')}>

	<input 
		data-filter={( typeof dataFilter !== 'undefined' ? dataFilter : null )} 
		readonly={( typeof readonly !== 'undefined' ? readonly : null )} 
		maxlength={( typeof maxlength !== 'undefined' ? maxlength : null )} 
		onkeypress="return ( event.keyCode == 13 ? false : true );" 
		
		id={id} 
		name={id} 
		type={type}
		class="input" 
		required={(typeof required !== 'undefined' ? required : null)} 
		pattern={(typeof pattern !== 'undefined' ? pattern : null)} 
		placeholder={(typeof placeholder !== 'undefined' ? placeholder : '')} 
		value={(typeof value !== 'undefined' ? value : '')} 
		disabled={(typeof disabled !== 'undefined' && disabled === 'true' ? 'disabled' : null )} 
		onchange={ typeof onchange !== 'undefined' ? onchange : null } 
		onkeyup={ typeof onkeyup !== 'undefined' ? onkeyup : null } 
		onkeydown={ typeof onkeydown !== 'undefined' ? onkeydown : null } 
		autocomplete={ typeof autocomplete !== 'undefined' ? autocomplete : 'off' } 
		/>
*/