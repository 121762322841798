import react from 'react';

import logo from '../Assets/images/logo192.png';

const style =
{
	logo: 
	{
		width: '64px',
		height: 'auto',
	}
}

export default function Logo( props ) 
{
	style.width = props?.size ?? '64px';

	return (
		<div className="flex flex-row flex-no-wrap items-center m:b4">
			<img src={logo} alt="Logo" style={ style.logo } className="m:r2"/>
			<h1 className="h3">ForestJS - OAK</h1>
		</div>
	);
}