import react from 'react';

import { Link } from "react-router-dom";


import { Input, Button } from '../Components/Form';
import Logo from '../Components/Logo';

const style =
{
	wrapper : 
	{
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	dialog:
	{
		position: 'relative',
		backgroundColor: '#fff',
		padding: '32px',
		boxShadow: '0 2px 6px rgb(0 0 0 / 20%)',
		
		width: '400px',
		height: '338px'
	}
}

export default function Login()
{
	return ( <>
			<h3>Create account</h3>
			<Input
				className="m:b2" 
				type="text" 
				name="email"
				placeholder="Email"/>
			<div className="m:h4">
				Have you account? <Link to="/login" className="text-bold text-lightblue"> Sing in!</Link>
			</div>
			<div className="flex justify-end">
				<Button
					loader={ true } 
					onClick={() =>{ console.log( 'click' ) } }
					label="Next"
					color="blue"/>
			</div>
			<div className="h6 m:t8 text-center">
				Choosing <span className="text-bold">Next</span> means that you agree to the <span className="text-lightblue pointer">ForestJS Agreement</span> and <span className="text-lightblue pointer">privacy and cookies statement</span>.
			</div>
		</> );
}