import react from 'react';
import { Outlet } from "react-router-dom";

import Logo from '../Components/Logo';

const style =
{
	wrapper : 
	{
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	dialog:
	{
		position: 'relative',
		backgroundColor: '#fff',
		padding: '32px',
		boxShadow: '0 3px 6px rgb(0 0 0 / 15%)',
		
		width: '400px',
		minHeight: '338px'
	}
}

export default function Login()
{
	return ( 
		<div style={ style.wrapper }>
			<div style={ style.dialog }>
				<Logo width={ '32px' }/>
				<Outlet/>
			</div>	
		</div> );
}