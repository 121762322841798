import react from 'react';

import { Link } from "react-router-dom";

import { Input, Button } from '../Components/Form';


export default function Login()
{
	return ( <>
			<h3>Sign in</h3>
			<Input
				className="m:b4" 
				type="text" 
				name="email"
				placeholder="Email"/>
			<Input
				className="m:b2" 
				type="password" 
				name="password"
				placeholder="Password"/>
			<div className="m:h4">
				No account? <Link to="/registration" className="text-bold text-lightblue"> Create one!</Link>
			</div>
			<div className="flex justify-end">
				<Button
					loader={ true } 
					onClick={() =>{ console.log( 'click' ) } }
					label="Sing in"
					color="blue"/>
			</div>
		</> );
}