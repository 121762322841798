import {
	BrowserRouter,
	Routes,
	Route
} from 'react-router-dom';

import AuthPage from './Pages/Auth';
import LoginPage from './Pages/Login';
import RegistrationPage from './Pages/Registration';
import DashboardPage from './Pages/Dashboard';
import PageNotFound from './Pages/NotFound';

import './Assets/css/reset.css';
import './Assets/css/index.css';

import './Assets/scss/solid.scss';
import './Assets/scss/regular.scss';
import './Assets/scss/fontawesome.scss';


function App() {
  return (
	  <BrowserRouter>
	  	<Routes>
			<Route path="/" element={<AuthPage/>}>
				<Route index element={<LoginPage/>} />
				<Route path="login" element={<LoginPage/>}/>
				<Route path="registration" element={<RegistrationPage/>}/>
			</Route>
			<Route path="/dashboard" element={<DashboardPage/>}>

			</Route>
			<Route path="*" element={<PageNotFound/>}/>
		</Routes>
	  </BrowserRouter>
  );
}

export default App;
